.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
    text-align: center;
  }
  
  .error-image {
    width: 300px; /* Adjust the size based on your image */
    margin-bottom: 20px;
  }
  
  .error-heading {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .error-message {
    font-size: 1.2em;
    color: #666;
  }