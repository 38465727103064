.bottom-note-section {
    display: flex;
    flex-direction: column;
    /* position: absolute;
    bottom: 0; */
  }
  
  .home-logo-contianer{
    width: 100%;
  }
  .cursor-pointer{
    cursor: pointer;
  }
  .home-logo-style{
    /* border: 1px solid red; */
    height: 80px;
    width: 80px;
    max-width: 100%;
    aspect-ratio: 4.86 / 1;
    object-fit: contain;
    object-position: center center;
  }
  .login-head-section {
    min-height: 100vh;
  }
  .book-btn-text-book{
    margin: auto;
    padding: 0px 10px;
  }
  .book-btn-text-manage{
    margin: auto;
    padding: 0px 10px;
  }
  
  .btn.btn-warning {
    min-width: 190px;
    min-height: 45px;
    font-size: 18px;
    font-family: 'Noto Sans', sans-serif;
    border-radius: 7.245px;
    border: 1.811px solid #000;
    background: #F6B93B;
  }
  .button-min-width{
    min-width: 160px;
  }
  .btn.btn-warning:hover {
    box-shadow: 7.2452826499938965px 7.2452826499938965px 0px 0px #000;
  }
  
  .font-size-40px {
    font-size: 40px;
  }
  .welcome-text{
    min-width:100%;
  }
  
  .font-size-30px {
    font-size: 25px;
  }
  
  .font-size-20px {
    font-size: 20px;
  }

  .font-size-18px {
    font-size: 18px;
  }

  .font-size-16px {
    font-size: 16px;
  }
  
  .margin-top-6-25rem {
    margin-top: 6.25rem;
  }
  
  .padding-left-60px {
    padding: 0 30px;
  }
  
  .disabled-telehealth {
    opacity: 0.6;
    cursor: not-allowed;
  }
  .index-book-arrow{
    
    margin-left: -10px;
    width: 100%;
   max-height: 300px;
   margin: auto;
  }

  .auth-submit-btn-home{
    width: 200px !important;
  }

  .text-justify{
    text-align: justify;
  }

  @media (max-width: 1200px) and (min-width: 567px) {
    .auth-submit-btn-home{
      width: 160px !important;
    }
    .login-head-section {
      min-height: 100vh;
    }
    .homepage-image{
      display: none !important;
    }
  }
  
  @media screen and (max-width:568px) {
    .auth-submit-btn-home{
      width: 150px !important;
    }
    .font-size-40px {
      font-size: 20px;
    }
    .home-logo-style{
      height: 30px;
    }
    .book-btn-text-book{
      margin: auto;
      padding: 0px 10px;
    }
    .book-btn-text-manage{
      margin: auto;
      padding: 0px 10px;
    }
    .font-size-30px {
      font-size: 20px;
    }
  
    .margin-for-mobile {
      /* margin: auto; */
    }
  
    .font-size-20px {
      font-size: 14px;
    }
  
    .padding-left-60px{
      padding: 0;
    }
    
    .btn.btn-warning {
      /* min-width: 80px; */
      font-size: 11px;
    }
    .login-head-section{
      /* height: calc(100vh - 52px); */
      padding: 0 18px;
      min-height: 100vh;
    }
    .button-min-width{
      min-width: 100px !important;
    }
  }
  .footer-with-next-option-pages{
    background: rgb(235, 235, 110);
    /* padding-bottom: 62px; */
  }
  .text-custom-css-align {
    word-break: break-all;
  }