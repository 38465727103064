.manage-btn.btn.btn-success {
    color: #ffffff;
    background-color: #2ead5a;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 0px;
    font-size: 1rem;
    min-width: 150px;
    cursor: pointer;
    border-radius: 30px;
    font-weight: bold;
}
.manage-booking-logo{
    padding: 0px 110px;
}
.login-header-logo-container{
    /* padding-left: 0% !important; */
    width: 15px;
    height: 45px;
    overflow: hidden; 
    display: flex;
    align-items: start;
    justify-content: center;
}
.fixed-box-image {
    /* width: 100%; */
    height: 100%;
    object-fit: contain; 
  }

.cancel-btn.btn.btn-success {
    color: black;
    background-color: #ffffff;
    border: 1px solid #0E1F3B;
    ;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    min-width: 150px;
    display: inline-block;
    margin: 4px 0px;
    font-size: 1rem;
    /* transition: font-size 0.4s 0s; */
    cursor: pointer;
    border-radius: 30px;
    font-weight: bold;
}

.cancel-btn.btn.btn-success:hover {
    background: #0E1F3B;
    color: #ffffff;
    /* font-size: 1.1rem; */
}

.status.badge.bg-success {
    font-size: 12px;
    border-radius: 30px;
    background-color: #2ead5a !important;
}

.container-area-padding {
    padding: 0% 11% !important;
}

.user-data-list {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px;
    border-radius: 12px;
}

[data-letters]:before {
    content: attr(data-letters);
    display: inline-block;
    font-size: 1em;
    font-weight: bold;
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em;
    text-align: center;
    border-radius: 50%;
    background: #2ead5a;
    vertical-align: middle;
    margin-right: 1em;
    color: white;
}

@media screen and (max-width:568px) {
    .login-header-logo-container{
        justify-content: start !important;
    }
    .container-area-padding {
        padding: 12px !important;
       
    }
    .manage-booking-logo{
        padding: 0px;
    }

    .title-area {
        flex-direction: column;
        align-items: center;
    }

    .manage-btn.btn.btn-success.min-width-for-mobile {
        padding: 12px;
        min-width: 75vw;
    }

    .user-data-list {
        margin: 10px;
        padding: 10px;
    }

    .flex-direction-column-mobile {
        flex-direction: column;
    }
    .cancel-btn.btn.btn-success {
        min-width: 140px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .container-area-padding {
        padding: 5px 60px !important;
       
    }
    .login-header-logo-container{
        padding-left: 0px !important;
    }
    .manage-booking-logo{
        padding: 5px 52px !important;
    }
    .cancel-btn.btn.btn-success {
        min-width: 140px;
    }
}