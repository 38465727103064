.pet-image {
    transition: .5s ease;
}

.selected-pet {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selected-pet-active {
    opacity: 0.5;
}

.pet-name {
    position: absolute;
    left: 0;
    top: 20px;
    padding: 5px 10px 5px 8px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}