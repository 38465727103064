.a_alda_logo_horizontal-2-2 {
  height: 35.337px;
  cursor: pointer;
}

.phone {
  color: var(--darkblue);
 font-family: 'Noto Sans', sans-serif;
  font-size: 12.06px;
  font-weight: 700;
  line-height: 16.42px;
  margin-bottom: 3.04px;
  min-height: 16px;
  min-width: 124px;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1200px;
  }
}

.frame-18222 {
  align-items: center;
  display: flex;
  gap: 50px;
  margin-left: -3px;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: space-around;
}

.group-18224-frame-18222,
.group-18227,
.group-18226,
.group-18225,
.group-18223 {
  height: 50px;
}

.group-18224-group-18223,
.group-18224-group-18224,
.group-18224-group-18224,
.group-18224-group-18226,
.group-18224-group-18225 {
  align-items: center;
  display: flex;
}

.raleway-bold-black-rock-16px {
  color: var(--black-rock);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}

.heading-info {
  color: #000339;
  font-size: 35px;
  font-weight: 600;
  line-height: 47.67px;
  display: flex;
}

.frame-18222.container {
  padding-bottom: 20px;
}

.step-btn-section {
  /* float: right; */
  font-size: 18px;
  font-weight: 700;
  line-height: 24.52px;
}

.col-5.first-column {
  padding-left: 35px;
  border-right: 1px solid grey;
  padding-top: 25px;
}

.col-7.second-col {
  padding-left: 50px;
  padding-top: 25px;
}

.row.first-row.container {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
}

.flex {
  flex: 1;
}

.flex-row-2 {
  align-items: flex-start;
  background-color: var(--zircon);
  display: flex;
  gap: 129px;
  height: 426px;
  margin-top: 19px;
  min-width: 1440px;
  padding: 0 40px;
}

.flex-col-2 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 6.39px;
  min-height: 374px;
  width: 365px;
}

.flex-row-3 {
  align-items: center;
  display: flex;
  min-width: 719px;
}

.text-container.valign-text-middle.notosans-semi-bold-ocean-blue-pearl-20px {
  color: #4141a5;
  font-family: var(--font-family-noto_sans);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.notosans-normal-bdazzled-blue-13px {
  color: #3c6097;
  font-family: var(--font-family-noto_sans);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.mobile-verify {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--granite-gray);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 5px;
  padding: 15px 25px 20px 10px;
  width: 360px;
}

.otp-sent-successfully {
  color: var(--japanese-laurel);
  font-family: var(--font-family-noto_sans);
  font-size: var(--font-size-xxxxs);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  white-space: nowrap;
  width: fit-content;
}

.mobile-verify,
.mobile-verify1 {
  color: #4141a5;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 5px;
}

/* .enter-number {
  font-size: 13px;
  font-weight: 400;
  color: #3c6097;
} */

.enter-phone1 {
  margin-left: 16px;
}

.text-form-div {
  margin-top: 25px;
  margin-left: 20px;
}

.first-row {
  margin-top: 70px;
}

.next-page {
  width: 140px;
  margin-top: 20px;
}

.summary {
  font-weight: 600;
  font-size: 20px;
  color: #000339;
}

.row-2 {
  margin-top: 40px;
}

.summary-div-p {
  margin-bottom: 0;
}

.date-and-time {
  margin-top: 50px;
}

.summary-div {
  margin-top: 50px;
}

.enter-phone {
  width: 40px;
}

.first-section {
  background: #f5f7f9;
}

p.summary-text {
  color: #4141a5;
  font-family: var(--font-family-noto_sans);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

p.summary-p.summary-div-p {
  color: #565656;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
}

p.summary-p {
  color: var(--lighgray);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

button.next-page {
  color: white;
  background-color: #09a23e;
  border-radius: 7.68px;
  display: flex;
  font-size: 23px;
  gap: 12.8px;
  justify-content: center;
  margin-top: 32px;
  padding: 6.8px 19.21px;
  width: fit-content;
  border: none;
}

.you-and-pet {
  color: #000339;
  /* font-family: var(--font-family-noto_sans); */
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}

.personal-info {
  color: #4141a5;
  /* font-family: var(--font-family-raleway); */
  font-size: 30px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 1px;
  margin-bottom: 0;
}

.provide-info {
  color: var(--lighgray);
  font-family: var(--font-family-noto_sans);
  font-size: 25px;
  font-weight: 400;
  line-height: 34.05px;
  letter-spacing: 1px;
}

.label-form {
  color: #565656;
  font-family: var(--font-family-noto_sans);
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
}

.label-input {
  align-items: center;
  border: 1px solid;
  border-color: var(--fuscous-gray);
  border-radius: 5px;
  display: flex;
  background-color: #f5f7f9;
  gap: 10px;
  padding: 10px 23px 10px 20px;
  width: 100%;
}

.label-input1 {
  align-items: center;
  border: 1px solid;
  border-color: var(--fuscous-gray);
  border-radius: 5px;
  display: flex;
  gap: 10px;
  background-color: #f5f7f9;
  padding: 10px 23px 10px 20px;
}

::placeholder {
  background-color: transparent;
  border: 0;
  height: 20px;
  letter-spacing: 0.65px;
  line-height: 20px;
  margin-top: -1px;
  min-width: 205px;
  padding: 0;
  white-space: nowrap;
}

.personal-form {
  margin-top: 120px;
  margin-left: 40px;
}

.pet-form {
  margin-top: 52px;
  margin-left: 40px;
}

.submit-button {
  float: right;
}

.section-two,
.header-section {
  background: #F5F7F9;
}

.only-mobile {
  display: none;
}

.thankyou,
.thankyou-sub {
  color: #4141a5;
}

@media (max-width: 980px) {

  .group-18224-frame-18222,
  .group-18227,
  .group-18226,
  .group-18225,
  .group-18223 {
    width: 20%;
    font-size: 11px;
    display: flex;
    font-weight: 600;
  }

  .mobile-verify {
    width: 100%;
    padding: 0px 11px 14px 0px;
    font-size: 16px;
  }
}

@media (max-width: 580px) {
  
}