.text-design {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
}

.year-section {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.border-line {
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
}

.days-slot {
  height: 63px;
  vertical-align: middle;
}

.day-date-section {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: var(--lighgray);
}

.slots-section {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: var(--textprimarycolor);
  margin-top: 12px;
}

.time-slots {
  border: 1px solid #9B9DD1;
  height: 38px;
  margin: 5px;
  min-width: 100px;
  background: transparent;
  border-radius: 10px;
  font-size: 18px;
  line-height: 24.52px;
  color: #3352a3;
  font-weight: 700;
  letter-spacing: 1px;
}

.booking-slots {
  overflow-y: auto;
  /* flex: 0 1 calc(16%); */
}

.left-part {
  font-weight: 500;
  text-align: center;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.02em;
  color: var(--lighgray);
  width: 12%;
}

.selected-slot {
  background-color: var(--maincolor);
  border: none;
  color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(217, 217, 217, 0.50);
}

.icons-logo {
  display: flex;
  justify-content: center;
}

.slots-section-mobile {
  color: #000339;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
}

.action-button {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: var(--textprimarycolor);
  border: none;
  background: transparent;
}

.date-year {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  color: var(--textprimarycolor);
}

.prev-button {
  margin-left: 2em;
}

.side-padding {
  padding: 5px 14px;
}

.next-buttuon {
  margin-right: 2em;
}

.year-txt {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 10px;
  color: var(--textprimarycolor);
  letter-spacing: 0.1em;
}

.text-area {
  min-width: 100px;
  text-align: center;
}

.line-shade {
  width: 100%;
  height: 1px;
  background: #EDEDED;
  margin-top: 15px;
}

.justify-content-cl {
  justify-content: space-around;
}

.session-text-color {
  color: #696969;
}

.select-days.session-text-color,
.select-days.slots-section {
  color: #fff;
}

.calenedar-section-color {
  background: #F5FAFE;
}

.selected-date-mobile {
  color: #151313;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.calendar-icon {
  color: #525050;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.date-section {
  color: #1E293B;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.3px;
  text-align: center;
}

.day-section {
  color: #94A3B8;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.3px;
}

.current-day {
  color: #525050;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.select-days .date-section,
.select-days .day-section {
  color: #fff;
}

/* customize mobile calendar css */
.react-calendar {
  box-shadow: 0 0 10px #00000030 !important;
  border: none !important;
  font-family: 'Noto Sans', sans-serif !important;
  border-radius: 10px !important;
}

.react-calendar__navigation button:disabled {
  background: #ffffff !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
}

.react-calendar__navigation button span {
  font-weight: bold;
  color: #2ecc71;
}

.react-calendar__month-view__days button {
  border-radius: 50%;
  height: 50px !important;
  margin-top: 1px !important;
}

.react-calendar__tile {
  font-weight: 500 !important;
}

.react-calendar__tile:disabled {
  background-color: #ffffff !important;
  color: rgb(112, 117, 122) !important;
}

.react-calendar__tile--active {
  background: #2ecc71 !important;
}

.react-calendar__tile--now {
  background: #e6e6e6 !important;
}

.react-calendar__tile--now.react-calendar__tile--active {
  background: #2ecc71 !important;
}

.react-calendar__navigation {
  padding: 5px !important;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba(10, 10, 10, 0.45) !important;
}

.react-calendar__month-view__weekdays__weekday{
  background-color: #2ecc71;
  color: #ffffff;
}
.react-calendar__month-view__days__day--weekend {
  /* color: #000000 !important; */
  color: inherit !important;
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
 color: gray !important;
}

.react-calendar__tile.react-calendar__month-view__days__day {
  color: black;
} 

@media (max-width: 580px) {
  .hide-for-mobile {
    display: none;
  }

  .show-for-mobile {
    display: block;
  }

  .time-slot-area {
    height: 30vh;
    overflow: auto;
  }

  .display-time-section {
    flex-direction: column;
    align-items: self-start;
  }

  .height-100 {
    height: 100%;
  }

  .time-slots {
    min-width: 66px;
    font-size: 14px;
    height: 34px;
  }

  .manage-alignment {
    justify-content: center;
    align-items: center;
  }

  .left-part {
    font-size: 14px;
    line-height: initial;
  }

  .initial-justify {
    justify-content: initial !important;
  }

  .slot-title-mobile {
    padding: 12px 0px ; 
  }

  .overflow-mob {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .margin-zero {
    margin: 0;
  }

  .padding-zero-mobile {
    padding: 0;
  }

  .font-size-0-75 {
    font-size: 0.75rem;
  }

  .padding-0-5rem {
    padding: 0.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .day-date-section {
    clear: both;
    display: inline-block;
    white-space: nowrap;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.05em;
    color: var(--lighgray)
  }

  .slots-section {
    clear: both;
    display: inline-block;
    white-space: nowrap;
    font-weight: 600;
  }

  .show-block {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .justify-content-cl {
    justify-content: initial !important;
  }

  .overflow-x-scroll-tab {
    overflow-x: scroll;
  }
}