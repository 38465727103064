*{
    font-family: Noto Sans;
}
.addpet-container{
    width: 100%;
    overflow-y: auto;
    overflow-x:hidden ;
}
.add-pet-form{
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 4px;
    /* margin-top: 30px; */
}

.row{
    margin: 0%;
}
.pet-dob-style{
    display: flex;
    flex-direction: column;
}
.dob-leble-style{
    margin-left: 12px;
}
.date-picker-style{
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    background: transparent;
    padding-left: 8px;
    height: 45px;
    padding: 5px 10px ;
}
.dob-state-style{
    margin-top: 0;
}


/* .elevaton-btn-group{
    margin-top: 23px;
    display: flex;
    width: 229px;
    padding-left: 0px;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #ACB0B8;
    background: var(--gray-300, #F3F4F6);
} */
.input-dob-section{
    gap:0rem;
    display: flex;
}


.MuiButtonGroup-root{
    /* margin-top: 23px; */
    display: flex;
    justify-content: center;
}
@media (max-width: 1092px){
    .add-pet-form{
        gap: 9px;
        margin-top: 20px;
    }
    .date-picker-style .dob-state-style{
        height: 40px;
    }
    .input-dob-section{
        gap:8px;
    }
    .MuiButtonGroup-root{
        display: flex;
        justify-content: center;
    }
    
    
}
@media (max-width: 1200px) and (min-width: 575px) {
    .add-pet--main-container{
        margin-top: 5% !important;
    }
  }
@media (max-width: 599px){
    .addpet-container{
        width: 100%;
    }

    .input-dob-section{
        display:grid;
        gap:0;
    }
    .date-picker-style {
        height: 40.68px;
    }
    .MuiButtonGroup-root{
        margin-top: 0;
        display: flex;
        justify-content: center;
    }
    .add-pet-container{
        margin: 0;
    }
    *{
        font-size: 15px;
    }
    .dob-style{
        display: flex;
        align-items: flex-end;
        gap: 0;
    }
    .dob-state-style{
        width: 92.5%;
    }
  
}
@media (min-width : 1093px)
{
    .add-pet-container{
       
    }
}