.doctor-view-card {
  width: 200px;
}

.doctor-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #292929;
}

.btn-prv-color {
  background: #02043A !important;
}
.btn-prv-color:hover {
  opacity: 0.9;
  color: #fff;
}
.btn-padding {
  padding: 30px 130px;
}

.doctor-subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #4d6e90;
}

.bcg-color {
  background-color: #F5F7F9;
}

.text-font {
  font-style: normal;
  font-weight: 400;
  font-size: 23.0481px;
  line-height: 28px;
}

fieldset {
  border-radius: 5px;
  padding: 0 20px;
  padding: 10px;
}

legend {
  background-color: #4c4c4c;
  color: #ffffff;
  padding: 5px;
  border-radius: 3px;
}

input:invalid:required {
  background-color: lightpink;
}

.input {
  border: none;
  border-radius: 4px;
  height: 25px;
  padding: 2px;
  font-size: 0.9em;
}

.section-thanks {
  background: #f5f7f9;
  height: 100vh;
  margin: 0;
  padding: 5rem 0;
}

.suggestion-thankyou {
  color: #3c6097;
}

.icon-size {
  width: 1.2rem;
}

.manage-booking {
  font-size: 23px;
  line-height: 40px;
  width: 250px;
  background: #09a23e;
  border-radius: 6.5929px;
  width: auto;
  height: 45px;
  border: none;
  color: white;
  padding: 10px;
}

label {
  font-size: 0.9em;
}

.parent-background {
  background-color: #f4f7f9;
}

.button-text {
  font-family: "GothamBook";
  font-weight: 400;
  font-size: 23.0481px;
  line-height: 27px;
}

.btn-color {
  background: #02043a;
}

.previous-btn {
  height: 52px;
  border-radius: 7px;
  min-width: 160px;
  border: none;
  color: #fff;
}

.button-text {
  font-size: 23.0481px;
  line-height: 27px;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

#submit {
  padding: 0;
}

#submit>button {
  background-color: #ccc;
  box-shadow: 2px 2px 5px gray;
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
}

#submit>button:active {
  box-shadow: 1px 1px 0px gray;
  background-color: #eee;
}

#submit>button:hover {
  background-color: aliceblue;
}

.calendar {
  margin: 20px 0;
}

.calendar>* {
  /* margin: 10px 0; */
}

/* h1 {
  position: relative;
  background-color: green;
  padding: 8px 30px;
  text-align: center;
  color: #fff;
  border-radius: 8px;
} */

h1 .btn {
  position: absolute;
  top: 20%;
  height: 60%;
  text-shadow: 2px 2px 5px rgb(35, 35, 46);
  border: 0;
  background: none;
  font-size: 1.2em;
  line-height: 0;
  color: lightgreen;
  cursor: pointer;
  opacity: 0.5;
}

h1 .btn:active {
  text-shadow: 0 0 2px rgb(35, 35, 46);
  color: #fff;
  opacity: 1;
}

h1 .prev {
  left: 10px;
}

h1 .next {
  right: 10px;
}

.calendar ul {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
  padding-left: 0;
}

.calendar li:nth-child(7n) {
  color: blue;
}

.calendar li:nth-child(7n-1) {
  color: red;
}

.weekdays {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.1);
  border-top: 2px solid;
  border-bottom: 2px solid;
  color: green;
  padding: 5px;
}

.days li {
  text-align: right;
  border: 1px solid green;
  background-color: rgba(0, 200, 0, 0.1);
  border-radius: 5px;
  padding: 5px 30% 5px 5px;
}

footer {
  font-size: 0.8em;
  text-decoration: underline;
  font-weight: 600;
  background-color: #7fffd4;
  text-align: center;
  padding: 4px;
}

footer:hover {
  cursor: pointer;
  text-decoration: none;
}

footer:active {
  color: red;
}

.overflow-section {
  overflow: hidden;
}

@media (max-width:780px) {
  .row-cols-md-5>* {
    min-width: 233px;
  }

  .padding-zero-tab {
    padding: 0;
  }
}

@media (max-width: 580px) {
  .hide-for-mobile {
    display: none;
  }

  .btn-position-fixed {
    position: fixed;
  }

  .margin-right-12 {
    margin-right: 12px;
  }

  .padding-bottom-4-rem {
    padding-bottom: 4rem;
  }

  .margin-left-5-px {
    margin-left: 5px;
  }

  .image-border-radius {
    border-radius: 8.5px;
  }

  .margin-right-5px {
    margin-right: 5px;
  }
}

@media (max-width:580px){
  .section-thanks{
    height: none;
  }
}