.box-conainer{
    width: 45% !important;
    height: 60px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
    
    .green-box{
        margin: 0;
        height: 90%;
        width: 40%;
        background-color: green;
    }
    .border-rounded{
        border-radius: 10px;
    }
    .reshedule-body-style>p{
        margin-bottom: 2%;
    }