.cards-dimensions {
  height: 230px !important;
  width: 90%;
}

.tick-icons {
  z-index: 10;
}

.active-tick {
  color: #fff;
}

.in-active-tick {
  color: rgba(9, 162, 62, 0.2);
}


.doctors-img-size {
  width: 100%;
  height: 148px;
  border-radius: 5px 5px 50px 0px;
  border-bottom-right-radius: 55px;
  object-fit: contain;
}

.img-border {
  border: 5px solid #F5F7F9;
  background-color: #fff;
}

.view-profile {
  font-size: 13px;
  width: 102px;
  height: 20px;
  position: absolute;
  color: #000339;
  top: 53.9%;
  line-height: 22px;
  left: 6px;
  padding-left: 12px;
  backdrop-filter: blur(2.34042px);
  border-radius: 0px 10px 10px 5px;
  /* border-radius: 0px 100px 100px 0px; */
}

.prefered-doctor {
  background: #09a23e !important;
}

.col.card-padding {
  margin-bottom: 1rem;
  position: relative;
}

.set-height {
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.doctor-name {
  font-weight: 500;
  margin-top: 13px;
  font-size: 18px;
  line-height: 21.6px;

}

.default-image-border{
  border: 5px solid #F5F7F9;
  background: #fff;
}
.default-text {
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  margin-top: 6px;
}

.doctor-specility {
  font-size: 14px;
  line-height: 14px;
  color: #4d6e90;
}

.tick-mark-box {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 27px;
  height: 26px;
  border-radius: 50%;
  background-color: #09a23e;
  /* right: 16px;
  top: 10px; */
  left: 10px;
  top: 13px;
}

.active-doctors-card {
  color: #fff;
  border-radius: 10px;
}

.active-doctors-card .view-profile {
  color: #000339;
}

.active-doctors-card .doctor-specility {
  color: #fff;
}

.border-default-img{
  border-radius: 10px 10px 50px 10px;
}
@media (max-width: 580px) {
  .cards-dimensions {
    height: 148px !important;
    width: 90%;
  }

  .view-profile {
    top: 32px;
    left: 43%;
    backdrop-filter: blur(0px);
  }

  .img-border {
    border: none;
  }

  .items-alignment {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .doctor-image-position {
    width: 43%;
    background-color: #F5F7F9;
  }

  .doctor-name {
    font-size: 18px;
    font-weight: 600;
    margin-left: 24px;
    margin-top: 26px;
  }

  .doctor-specility {
    font-size: 14px;

    margin-left: 24px;
    margin-top: 10px;
  }

  .default-alignment {
    width: 57%;
  }

  .set-height {
    height: 100%;
    text-align: left;
  }

  .view-profile {
    margin-left: 20px;
  }

  .padding-left-9px {
    padding-left: 9px;
  }

  .active-doctors-card .view-profile {
    color: #fff;
  }

  .doctors-img-size {
    padding-bottom: 12px;
    height: 146px;
    border-bottom-right-radius: 0;
    border-radius: 10px;
  }

  .flex-row-class {
    flex-direction: row !important;
  }

  .active-tick {
    color: #09a23e;
  }

  .tick-mark-box {
    background-color: #fff;
  }

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .row-cols-img {
    width: 40% !important;
  }
}