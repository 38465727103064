.auth-bg-section {
    height: 25%;
}
.login-header-logo-container{
    padding-left: 2.5rem;
    width: auto;
    height: 40px;
    overflow: hidden; 
    display: flex;
    align-items: start;
    justify-content: center;
}
.fixed-box-image {
    /* width: 100%; */
    height: 100%;
    object-fit: contain; 
  }

@media (max-width: 598px) {
    .auth-logo-wrapper {
        display: none;
    }
    .login-header-logo-container{
        /* padding-left: 3%; */
        padding-left: 20px;
        width: 160px;
        height: 36px;
    }
    .auth-logo-wrapper-mobile {
        display: block;
    }
    .mobile-language-change{
        /* border: 1px solid red; */
        margin-top: -6%;
        margin-right: 2%;
    }
}
@media (min-width: 598px) {
    .auth-logo-wrapper-mobile {
        display: none;
    }
}
@media (min-width: 1093px) {
    .auth-bg-section {
        height: 100%;
        width: 100%;
    }
    
    #auth-layout{
        display: flex;
        flex-direction: row-reverse;
        height: 100vh;
    }
    #image-container{
        width: 100%;
    }
}

@media (max-width: 1024px) {
    .auth-bg-section {
        height: 100%;
        border-radius: 10px;
        width: 100% !important;
    }
    #auth-layout{
        /* display: grid; */
        height: 100%;
        /* flex-direction: column; */
        gap: 4px;
    }
    #image-container{
        height: 200px;
        padding: 15px;
    }
}
@media ( min-width:576px) and (max-width: 1092px) {
   
    #image-container{
        display: none;
        height: 300px;
        padding: 15px;
    }
    #auth-layout{
        height: 92vh;
    }
}
@media ( max-width:576px){
    /* #auth-layout{
        height:110vh;
    } */
}
@media ( max-width:380px){
    /* #auth-layout{
        height:100vh;
    } */
}
