.font-family-text {
  font-size: 23px;
  line-height: 27.6px;
  letter-spacing: 1px;
}


.thanks-section {
  background: #F5F7F9;
}
.tankyou-contianer{
  min-height: 90vh;
  background: #F5F7F9;
}

.title-text {
  font-weight: 800;
  font-size: 34.17px;
  line-height: 46.54px;
}

/* .sub-text {
  font-weight: 500;
  font-size: 25.63px;
  line-height: 34.91px;
  font-family: 'Noto Sans', sans-serif;
} */

.suggestion-text {
  color: #3c6097;
  line-height: 17.45px;
}

.font-size-13px {
  font-size: 13px;
}

.font-size-1-563rem {
  font-size: 1.563rem;
}

.manage-button {
  text-align: center;
  font-size: 23px;
  /* padding: 10.988px 16.482px; */
  justify-content: center;
  align-items: center;
  gap: 10.988px;
  border-radius: 6.593px;
  margin-top: 30px;
  width: 240px;
  height: 50px;
}

.btn-lg.btn.btn-success {
  /* background: #09A23E; */
  color: #F5F7F9;
  border: none;
  /* border: 0; */
}

.padding-1-5rem {
  padding: 1rem 1.5rem;
}

@media (max-width:580px) {
  .cards-padding {
    padding: 11px 24px 11px 21px;
  }

  .font-size-13px {
    white-space: pre;
  }

  .summary-cards-padding {
    padding: 0 24px;
  }
  .thankYou-container{
    height: 94vh;
  }
}
@media (max-width:1049px) {
  *{
    font-size: small;
  }
  .thankYou-container{
    height: 99vh;
  }
}


/* nitin edit  */

.thnakYouPage-contianer{
  margin: 0;
  padding: 0;
  background: #F5F7F9;
  min-height: 90vh;
}
.heading-contianer{
  margin: auto;
  text-align: center;
  
}
.calender-img{
  width: 76.889px;
  height: 76.889px;
  flex-shrink: 0;
  margin-top: 10px;
}
.thankyou-heading {
  color: #4141A5;
  text-align: center;
  font-family: Noto Sans;
  font-size: 34.173px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.thankyou-sub{
  color: #4141A5;
  font-family: Noto Sans;
  font-size: 25.63px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.session-summary-conainer{
  margin: auto;
  width: 50%;
  margin-top: 0.5%;
  padding: 25px;
  border-radius: 12px;
  border: 0.854px solid #E1F0FA;
  background: #FEFEFE;
}
.disclaimer-container{
  margin: auto;
  width: 30%;
  margin-top: 0.5%;
  margin-bottom: 0.5%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-size{
  height: 13px;
  width: 13px;
}
.disclaimer-text{
  color: #3C6097;
  font-family: Noto Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  margin-left: 1.5%;
}
.manage-booking-btn{
  text-align: center;
  margin: 0;
  margin-bottom: 0.5%;
  margin-top: 0px;
}
@media (max-width:580px) {
  .thnakYouPage-contianer{
    margin-top: 2px;
  }
  .session-summary-conainer{
    width: 90%;
    padding:2%;
    margin-top: 0.5%;
  }
  .disclaimer-container{
    margin: auto;
    width: 30%;
    margin-top: 1%;
    margin-bottom: 0.5%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .disclaimer-container{
    width: 80%;
    margin: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

