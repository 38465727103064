.title-heading {
  color: #4141a5;
  font-size: 20px;
  font-weight: 600;
  /* line-height: 29.09px; */
  letter-spacing: 1px;
}

.content-margin {
  margin-top: 0;
}

/* .content-value {
  color: #696969;
  font-size: 18px;
  letter-spacing: 1.8px;
  line-height: 24.52px;
  font-weight: 500;
} */
.content-value-subhead {
  color: #696969;
  font-size: 18px;
  letter-spacing: 1.8px;
  /* line-height: 21.79px; */
  font-weight: 500;
}

.text-left {
  text-align: left;
}

.noto-font-bold {
  font-weight: 700;
}

.thanks-section {
  /* background: #f5f7f9; */
  /* height: 100vh; */
  margin: 0;
  padding: 5px 0;
}

.summary-category-header {
  color: #000339;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
}

.summary-hospital-name-doctor {
  color: #696969;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.8px;
}

.summary-hospital-address {
  color: #696969;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.summary-date-time {
  color: #696969;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.6px;
}

.summary-date-time-bold {
  color: #696969;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.8px;
}

.summary-service {
  color: #696969;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.6px;
}

.summary-service-bold {
  color: #696969;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.8px;
}

@media (max-width: 576px) {
  .text-margin {
    margin-top: 0.5rem;
  }

  .content-margin {
    margin-top: 0;
  }

  .title-heading {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  
  .title-heading {
    font-size: 18px;
  }

  .content-value-subhead {
    font-size: 14px;
  }

}