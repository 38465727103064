.a_alda_logo_horizontal-2-2 {
  height: 40px;
  cursor: pointer;
}

.appointment-note-container{
  height: 90%;
}

.country-drop-down-list select {
  font-size: 18px;
  line-height: 20px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("../../images/DownArrow.svg") no-repeat;
  background-position: right center;
  background-size: 10px;
  padding-right: 20px;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.min-height-area {
  min-height: calc(100vh - 127px);
}

.summary-header {
  color: #4141A5;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* .raleway-bold-black-rock-16px {
  color: var(--black-rock);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
} */


.heading-info {
  color: #000339;
  font-size: 35px;
  font-weight: 700;
  display: flex;
}

.breadcrumb-header {
  /* background-color: #f5fafeff; */
  background: #F5F7F9;
}

.side-design {
  width: 164.51px;
  height: 53.61px;
}

.col-5.first-column {
  padding-left: 35px;
  border-right: 1px solid grey;
  padding-top: 25px;
}

.col-7.second-col {
  padding-left: 50px;
  padding-top: 25px;
}

.row.first-row.container {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 35px;
}

.flex {
  flex: 1;
}

.flex-row-2 {
  align-items: flex-start;
  background-color: var(--zircon);
  display: flex;
  gap: 129px;
  height: 426px;
  margin-top: 19px;
  min-width: 1440px;
  padding: 0 40px;
}

.flex-col-2 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 6.39px;
  min-height: 374px;
  width: 365px;
}

.flex-row-3 {
  align-items: center;
  display: flex;
  min-width: 719px;
}

.text-container.valign-text-middle.notosans-semi-bold-ocean-blue-pearl-20px {
  color: #4141a5;
  font-family: var(--font-family-noto_sans);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.notosans-normal-bdazzled-blue-13px {
  color: #3c6097;
  font-family: var(--font-family-noto_sans);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.otp-sent-successfully {
  color: var(--japanese-laurel);
  font-family: var(--font-family-noto_sans);
  font-size: var(--font-size-xxxxs);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  white-space: nowrap;
  width: fit-content;
}

.mobile-verify,
.mobile-verify1 {
  letter-spacing: 1px;
  /* line-height: 27.24px; */
}

.enter-number {
  letter-spacing: 0.26px;
  /* line-height: 17.71px; */
  font-size: 13px;
  color: #3C6097;
}

.enter-phone1 {
  margin-left: 16px;
}

.first-row {
  margin-top: 70px;
}

.code-confirm {
  color: #4141a5;
  font-weight: 600;
  font-size: 20px;
  margin-top: 40px;
  margin-bottom: 20px;
  letter-spacing: 1px;
  /* line-height: 27.24px; */
}

.confirm-number-code {
  font-size: 13px;
  line-height: 16px;
  /* font-weight: 500; */
  color: #696969;
  margin-top: 5px;
}

.next-page {
  /* width: 140px; */
  margin-top: 20px;
  font-style: normal;
  /* font-weight: 400; */
  font-size: 20px;
  /* line-height: 28px; */
  width: 164.51px;
  height: 53.61px;
}

.margin-top-0-5rem {
  margin-top: 0.5rem;
}

.margin-top-0-5rem {
  margin-top: 0.5rem;
}

.margin-top-2-875rem {
  margin-top: 2.875rem;
}

.summary {
  font-weight: 600;
  font-size: 20px;
  color: #000339;
}

.row-2 {
  margin-top: 40px;
}

.summary-div-p {
  margin-bottom: 0;
}

.date-and-time {
  margin-top: 50px;
}

.summary-div {
  margin-top: 50px;
}

.padding-left-0-375rem {
  padding-left: 0.375rem;
}

p.summary-p.summary-div-p {
  color: #565656;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
}

p.summary-p {
  color: var(--lighgray);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

button.next-page {
  color: white;
  align-items: center;
  background-color: #09a23e;
  border-radius: 7.68px;
  display: flex;
  font-size: 23px;
  gap: 12.8px;
  justify-content: center;
  margin-top: 32px;
  padding: 6.8px 19.21px;
  width: fit-content;
  border: none;
}

button.submit-form {
  color: white;
  font-style: normal;
  line-height: 58px;
  background-color: #09a23e;
  border-radius: 7.68px;
  display: flex;
  font-size: 23px;
  gap: 12.8px;
  justify-content: center;
  margin-top: 32px;
  padding: 6.8px 19.21px;
  font-family: "GothamBook";
  border: none;
  width: 164.51px;
  height: 53.61px;
}

.you-and-pet {
  color: #000339;
  font-size: 35px;
  /* font-style: normal; */
  font-weight: 700;
  margin-top: 35px;
  letter-spacing: 1.05px;
}

.personal-info {
  color: #4141a5;
  font-family: "GothamMedium";
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 0;
  letter-spacing: 1.5px;
}

.provide-info {
  color: #696969;
  font-size: 25px;
  /* font-weight: 500; */
  letter-spacing: 0.75px;
}

.label-form {
  color: #565656;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
}

.label-input {
  align-items: center;
  border: 1px solid;
  border-color: var(--fuscous-gray);
  border-radius: 5px;
  display: flex;
  background-color: #f5f7f9;
  gap: 10px;
  padding: 10px 23px 10px 20px;
  width: 100%;
}

.label-input1 {
  align-items: center;
  border: 1px solid;
  border-color: var(--fuscous-gray);
  border-radius: 5px;
  display: flex;
  gap: 10px;
  background-color: #f5f7f9;
  padding: 10px 23px 10px 20px;
  width: 100%;
}

::placeholder {
  background-color: transparent;
  border: 0;
  height: 20px;
  letter-spacing: 0.65px;
  line-height: 20px;
  margin-top: -1px;
  min-width: 205px;
  padding: 0;
  white-space: nowrap;
}

.submit-button {
  float: right;
}

.section-two,
.header-section {
  background: #F5F7F9;
}

.form-error {
  color: red;
}

.submit-btn-position {
  display: block;
  margin-left: auto;
  margin-right: 35px;
  margin-top: 16px;
}

.otp-status-txt {
  display: flex;
  position: absolute;
  right: 0;
  bottom: -25px;
  font-size: 13px;
  /* line-height: 16px; */
  font-weight: 400;
  /* color: var(--maincolor); */
  color: #007500;
}

.mobile-input-box {
  display: flex;
}

.mobile-input {
  border: none;
  background: transparent;
  border-radius: 0;
  border-bottom: 1px solid;
  width: 100%;
}

.send-again-txt {
  display: flex;
  margin-left: 1.2rem;
  align-items: center;
  cursor: pointer;
}

.refresh-icon {
  color: #0049b7;
  margin-right: 0.2rem;
}

.refresh-icon svg {
  width: 12px;
}

.send-txt {
  color: #0049b7;
  font-size: 13px;
  font-weight: 500;
}

.form-label-color {
  color: #696969;
}

.div-mobile {
  display: none;
}

.margin-top-0-6rem {
  margin-top: 0.6rem;
}

.div-desktop {
  display: block;
}

.disabled {
  pointer-events: none;
}

.flag_position{
  top: 26px;
}

#partitioned {
  padding-left: 8px;
  letter-spacing: 42px;
  border: 0;
  outline: 0;
  background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 220px;
  min-width: 220px;
  font-size: 13px;
}

#divInner {
  left: 0;
  position: sticky;
}

#divOuter {
  width: 190px;
  overflow: hidden;
}
.btn-contianer{
  margin-top: 1px;
}
.appointment-submit-btn-container{
  margin-top: -5%;
}

@media (max-width: 1200px) and (min-width: 579px) {
  .appointment-submit-btn-container{
    margin-top: -18%;
  }
}

@media (max-width: 580px) {
  .country-drop-down-list {
    width: 80px;
  }
  .btn-contianer{
    /* border: 1px solid red; */
  }
  .appointment-note-container{
    height: 64vh;
  }
  .a_alda_logo_horizontal-2-2 {
    height: 30px;
    cursor: pointer;
  }

  .flag_position {
    top: 14px;
    left: 13px;
  }

  .margin-top-0-875rem {
    margin-top: 0.875rem;
  }

  .margin-top-zero {
    margin-top: 0;
  }

  .margin-right-zero {
    margin-right: 0;
  }

  .margin-zero {
    margin: 0 !important;
  }

  .padding-top-12px {
    padding-top: 12px;
  }

  .title-font {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
  }

  .mobile-input {
    margin-left: 8px;
  }

  #div-mobile {
    display: block;
  }

  #div-desktop {
    display: none;
  }

  .country-drop-down-list select {
    font-size: 15px;
  }

  .padding-zero-mobile {
    padding: 0;
  }

  .no-display {
    display: block;
  }

  .padding-top-1-25rem {
    padding-top: 1.25rem;
  }

  .you-and-pet {
    font-size: 18px;
    margin-top: 22px;
    padding-top: 35px;
    /* margin-left: 12px; */
  }

  .personal-info {
    font-size: 18px;
    line-height: normal;
  }

  .provide-info {
    font-size: 13px;
    line-height: normal;
  }

  .height-100-vh {
    height: 10vh;
  }

  /* 
  .enter-number {
    font-size: 12px;
    white-space: nowrap;
  } */

  .height-50px {
    
    height: 50px;
  }

  .margin-left-12px {
    margin-left: 10px;
  }

  .margin-top-0-5rem {
    margin-top: 0;
  }

  .hide-title {
    display: none;
  }
}

/* @media (max-width: 780px) {
  .hide-title {
    display: none;
  }
} */

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .padding-right-zero-tablet {
    padding-right: 0 !important;
  }
 

  /* .hide-title {
    display: none;
  } */
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media screen and (max-width: 764px) {
  #contact-info {
    height: 88vh;
  }

 
  .appointment-notes-summary {
    background-color: "#F5F7F9";
    padding: 0px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1200px;
  }
}
.enterInfoExistingAndManageUsers-container{
  height: 81vh;
}
.appointment-notes-summary {
  background-color: "#F5F7F9";
  margin-top: 3%;
}