@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

@font-face {
  font-family: "GothamBold";
  src: local("GothamBold"),
    url("./Fonts/Gotham/GothamBold.otf") format("OpenType");
  font-weight: 700;
}

@font-face {
  font-family: "GothamMedium";
  src: local("GothamMedium"),
    url("./Fonts/Gotham/GothamMedium.otf") format("OpenType");
  font-weight: 500;
}

@font-face {
  font-family: "GothamBook";
  src: local("GothamBook"),
    url("./Fonts/Gotham/GothamLight.otf") format("OpenType");
  font-weight: 400;
}


:root {
  --root-bg-color: #f5f7f9;
  --maincolor: #09a23e;
  --textprimarycolor: #4141a5;
  --darkblue: #000339;
  --selectedhead: #0c073a;
  --lightgray: #696969;
}

body {
  font-family: 'Noto Sans', sans-serif;
  background: #f5f7f9;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

a, a:link, a:hover, a:active {
  text-decoration: none;
}

/* ::selection {
  color: red;
  background: rgb(121, 215, 207);
} */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.pointer-icon {
  cursor: pointer;
}

.truncated-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.truncated-text-2-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.truncated-text-1-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.open-color {
  color: #007500;
}

.card-text-gray {
  color: rgba(0, 48, 96, 0.70);
}

.down-tip-color {
  color: #4D6E90;
}

.display-flex-step {
  display: flex;
}

.card-1 {
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.card-1:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 1px 1px rgba(0, 0, 0, 0.10);
}

.padding-130 {
  padding: 0 0px;
}

.pointer {
  cursor: default;
}

.font-text-14px {
  font-size: 14px;
}

.padding-top-2-188rem {
  padding-top: 2.188rem;
}

.margin-top-4-25rem {
  margin-top: 4.25rem;
}


.margin-top-4rem {
  margin-top: 4rem;
}

.margin-top-3rem {
  margin-top: 3rem;
}


.margin-top-10 {
  margin-top: 10px !important;
}

.font-gottam {
  font-family: "GothamBook";
}

.font-gottam-bold {
  font-family: "GothamBold";
}

.font-gottam-medium {
  font-family: "GothamMedium";
}

.margin-top-2-125rem {
  margin-top: 2.125rem;
}

.margin-top-2-5rem {
  margin-top: 2.5rem;
}

.margin-bottom-2-5rem {
  margin-bottom: 2.5rem;
}

.margin-top-1-875rem {
  margin-top: 1.875rem;
}

.margin-top-0-675rem {
  margin-top: 0.625rem;
}

.margin-top-2-rem {
  margin-top: 2rem;
}

.margin-left-0-625rem {
  margin-left: 0.625rem;
}

.padding-top-0-rem {
  padding-top: 0rem;
}

.padding-right-0-rem {
  padding-right: 0 !important;
}

.padding-top-2-5-rem {
  padding-top: 2.5rem !important;
}

.no-outline {
  outline: none;
}

.no-display {
  display: none;
}

.modal-header {
  border-bottom: 0 !important;
}

.modal-footer {
  justify-content: center !important;
  border-top: 0 !important;
  padding: 0%;
}

.close-btn.btn.btn-secondary {
  font-size: 16;
  border-radius: 30px;
  min-width: 130px;
  background-color: #fff;
  color: rgb(14, 31, 59);
}

.close-btn.btn.btn-secondary:hover {
  color: #fff;
  background-color: rgb(14, 31, 59);
}

.cancel-btn.btn.btn-primary {
  border-radius: 30px;
  background-color: #2EAD5A;
  min-width: 130px;
  border: none;
}

.reschedule-btn.btn.btn-primary {
  border-radius: 30px;
  background-color: #2EAD5A;
  min-width: 130px;
  border: none;
}

.loader-position {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
}

.border-around-card {
  border: 1px solid #E1F0FA !important;
}

.loader-designing {
  display: flex;
  justify-content: center;
  background-color: #5a5a5a78;
  height: 100%;
  align-items: center,
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.select-card {
  text-decoration: none;
}
.previous-button-style{
color: white;
width: 158.78px;
height: 52.61px;
}
.go-next-page {
  text-decoration: none;
  width: 158.78px;
  height: 52.61px;
  /* background: #09a23e !important; */
  border-radius: 7.68px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 23.05px;
  line-height: 66px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #f5f7f9 !important;
  border: none;
}

.margin-top-negative-1-5rem {
  margin-top: -1.5rem;
}

.margin-bottom-3-rem {
  margin-bottom: 3rem;
}

.margin-left-0-875-rem {
  margin-left: 0.875rem;
}

.min-height-2-5rem {
  min-height: 2.5rem;
}

.social-networking-icon:hover {
  background-color: #fff;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  /* background-color: red;
  color: white; */
  text-align: center;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.block {
  flex: 0 0 33.33%;
  /* This will make each block take up 33.33% of the row width */
  box-sizing: border-box;
  /* To include padding and border within the 33.33% width */
  padding: 10px;
  /* Optional padding for each block */
}

.show-info-details {
  border: none;
  background: transparent;
  margin-right: 5px;
  width: 210px;
  height: 27px;
  font-size: 18px;
  line-height: 27px;
  color: var(--darkblue);
}

/* .change-card-style,
.change_card_style {
  background-color: #09a23e;
} */

.dcc-icon-image{
  height: 40px;
}

.change-card-style .card-subtitle,
.change-card-style .card-text,
.change-card-style .fourth-svg,
.change-card-style .service-card-header,
.change-card-style .service-card-subheader,
.change-card-style .service-card-para,
.change-card-style .service-card-footer {
  background-color: #09a23e;
  color: #fff;
}

.notosans-medium-ocean-blue-pearl-13px {
  color: var(--textprimarycolor);
  ;
  font-size: 16px;
  line-height: 17.71px;
  letter-spacing: 1px;
}

.title-sub-head {
  color: var(--textprimarycolor);
  ;
  font-weight: 500;
  font-size: 13px;
  line-height: 17.71px;
  letter-spacing: 1px;
}

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);
}

.calendar .header {
  font-weight: 700;
  font-size: 115%;
  border-bottom: 1px solid var(--border-color);
}

.background-color-info{
  background: #f5f7f9;
}

.calendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}

.calendar .header .icon:hover {
  transition: 0.25s ease-out;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 3em;
}

.calendar .header .icon:last-of-type {
  margin-right: 2em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  padding: 0.75em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .days .col {
  color: var(--textprimarycolor);
  font-size: 18px;
  font-weight: 500;
}

.calendar .body .cell {
  position: relative;
  height: 4em;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
  font-size: 1.5em;
}

.calendar .body .cell:hover {
  background: var(--bg-color);
  transition: 0.5s ease-out;
}

.calendar .body .selected {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
  border-image-slice: 1;
}

.calendar .body .today {
  border-left: 10px solid transparent;
  border-image-slice: 1;
}

.calendar .body .row {
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  color: #696969;
  font-weight: 500;
  font-size: 18px;
}

.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -0.2em;
  right: -0.05em;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}

.calendar-header {
  color: var(--textprimarycolor);
  font-weight: 600;
  font-size: 18px;
}

.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
  opacity: 0.05;
  transition: 0.5s ease-in;
}

.calendar .body .cell.today .bg {
  color: #ff1a79;
  opacity: 0.05;
}

.calendar .body .col {
  flex-grow: 0;
  text-align: center;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}

.padding-right-1-563rem {
  padding-right: 1.563rem;
}

.margin-right-2-3rem {
  margin-right: 2.3rem;
}

.noto-font {
  font-family: 'Noto Sans', sans-serif;
}

.more-bold {
  font-weight: 700;
}

.semi-bold {
  font-weight: 600;
}

.light-weight {
  font-weight: 500;
}

.light-weight-400 {
  font-weight: 400;
}

.no-slot-color {
  color: #696969;
}

.letter-spacing-3px {
  letter-spacing: 3px;
}

.font-size-1-125rem {
  font-size: 1.125rem;
}

.font-size-1-375rem {
  font-size: 1.375rem;
}

.font-size-0-815rem {
  font-size: 0.815rem;
}

.padding-btn-bottom {
  background: #F5F7F9;
  padding: 26px 130px;
}

.background-head-color {
  background: #F5F7F9;
}

.margin-left-10px {
  margin-left: 10px;
}

.margin-right-6px {
  margin-right: 6px;
}

.margin-right-10px {
  margin-right: 10px;
}

.margin-top-15px {
  margin-top: 15px !important;
}

.rounded-box{
  border-radius: 25px;
  border: 1px solid black;
  padding: 20px; 
  width: 130px;
  height: 85px; 
}

.form-select:focus{
  box-shadow: none !important;
}

.separator {
  margin: 0 0.5em;
  color: gray;
}

.language-label {
  margin-right: 0.5em;
  cursor: pointer;
  transition: color 0.3s;
}


.selected-language {
  color: lightskyblue;
}


.text-transform-char{
  text-transform: capitalize;
}


@media (max-width: 580px) {
  .padding-130 {
    padding: 0 8px;
  }

  .dcc-icon-image{
    height: 30px;
  }

  .padding-top-first-section {
    padding-top: 1.563rem;
    padding-left: 1.563rem;
    padding-right: 1.563rem;
  }

  .padding-bottom-5-rem {
    padding-bottom: 5rem;
  }

  .margin-left-30px {
    margin-left: 30px;
  }

  .padding-left-mob {
    padding-left: 0 !important;
  }

  .margin-top-0-5rem {
    margin-top: 0.5rem;
  }

  .margin-top-3-rem {
    margin-top: 3rem;
  }

  .margin-top-2-rem {
    margin-top: 2rem;
  }

  .margin-top-1-25rem {
    margin-top: 1.25rem !important;
  }

  .my-fixed-element {
    position: fixed;
    width: 100%;
    z-index: 999;
  }

  .padding-top-5-rem {
    padding-top: 5rem;
  }

  .padding-top-4-rem {
    padding-top: 4rem;
  }

  .padding-top-3-rem {
    padding-top: 3rem;
  }

  .hide-for-mobile {
    display: none;
  }

  .show-for-mobile {
    display: block;
  }

  .padding-zero {
    padding: 0 !important;
  }

  .margin-right-0-880rem {
    margin-right: 0.880rem !important;
  }

  .margin-bottom-2-rem {
    margin-bottom: 2rem;
  }

  .margin-right-2-3rem {
    margin-right: 1rem;
  }

  .title-size {
    font-size: 20px;
    font-weight: 600;
    line-height: 27.24px;
  }

  .margin-top-2-25rem {
    margin-top: 2.25rem;
  }
  /* for adjusting footer on pages with next button*/
  .footer-with-next-option-pages{
    padding-bottom: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .padding-130 {
    padding: 0 0px;
  }

  .no-display-for-tablet {
    display: none !important;
  }

  .padding-btn-bottom {
    padding: 30px 60px;
  }

  .day-slot {
    clear: both;
    display: inline-block;
    white-space: nowrap;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.05em;
  }

  .hide-for-tablet {
    display: none;
  }
}

/* AUTH CSS */
.auth-login {
  width: 34rem;
}
.login-inputs {
  height: 56px;
  padding:10px;
  border-radius: 4px;
  border: 1.5px solid #ACB0B8;
  gap: 18px;
}
.login-container{
  width: 70%;
}

@media (max-width: 1200px) and (min-width: 575px) {
  .login-main-container{
    height: 53vh;
  }
  .create-profile-container{
    margin-top: 10% !important;
  }
}
@media (max-width:576px)
{
  .login-container{
    width: 93%;
  }
  .login-inputs {
    width: 100%;
  height: 50px;
    padding: 4px 8px;
    border-radius: 4px;
    border: 1.5px solid #ACB0B8;
    gap: 18px;
    display: flex;
  }
  .auth-login {
    width: 24rem;
  }
  .contact-number{
    width: 350px;
  }
}
.contact-number{
  width: 552px;
}
.login-heading {
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 0em;
  color: #1F2124;
}
.login-sub-heading {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  color: #4B4B5A;
}
.tabs-list {
  width: 509px;
  height: 44px;
  border-radius: 4px;
  border: 1px;
}
.contact-tab-switch {
  width: 169px;
  height: 44px;
  padding: 10px, 13px, 10px, 13px;
  border-radius: 4px;
  gap: 4px;
}
.pet-tab-switch {
  width: 170px;
  height: 44px;
  padding: 10px, 59px, 10px, 58px;
  border-radius: 4px;
  gap: 4px;
}
.email-tab-switch {
  width: 170px;
  height: 44px;
  padding: 10px, 24px, 10px, 24px;
  border-radius: 4px;
  gap: 4px;
}

.auth-label {
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.redAsterisk::after {
  content: "*";
  color: red;
  font-size: 18px;
  font-weight: 500;
}

.contact-number {
  width: 100%;
  height: 64px;
  padding: 8px 8px 8px 54px;
  border-radius: 4px;
  border: 1.5px solid #ACB0B8;
  gap: 18px
}

.auth-submit-btn {
  width: 100%;
  height: 55px;
  padding: 18px, 14px, 18px, 14px;
  border: 0;
  border-radius: 8px;
  gap: 8px;
  font-size: 30px;
  font-weight: 800;
  line-height: 33px;
  letter-spacing: 0em;
}


.auth-secondary-btn {
  width: 552px;
  height: 49px;
  padding: 12px, 16px, 12px, 16px;
  border-radius: 4px;
  border: 1px solid #D8DAE5;
  gap: 8px;
  background-color: #FFFFFF;
  font-family: Noto Sans;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;
  color: #696F8C;
}

.otp-containter{
  gap: 1.5rem;;
}
.otp-mobile-hint{
  width: 65%;
  margin: auto;
  margin-bottom: 12px;
}
.login-heading-style{
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0em;
}
@media (max-width : 576px)
{
  .otp-mobile-hint{
    width: 90%;
  }
  .otp-containter{
    gap:.2rem
  }
  .footer-with-next-option-pages{
    padding-bottom: 56px;
  }
  .login-heading-style{
    font-size: 28px;
    font-weight: 500;
  }
  .login-sub-heading{
    margin: auto;
    font-size: 14px;
    font-weight: 400;
    width: 90%;
    margin-bottom: 6px;
  }
}

